import styled from 'styled-components';
import { Container } from '../../PageTemplate';
import { GenIcon } from 'react-icons';
// import { GrShieldSecurity } from 'react-icons/gr';

// import { Link } from 'react-router-dom';

function GrShieldSecurity (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24"},"child":[{"tag":"path","attr":{"fill":"none","stroke":"#fff","strokeWidth":"2","d":"M12,22 C12,22 2.99999999,18 3,11 L3,5 L12,2 L21,5 C21,5 21,11 21,11 C21,18 12,22 12,22 Z M12,14 C13.6568542,14 15,12.6568542 15,11 C15,9.34314575 13.6568542,8 12,8 C10.3431458,8 9,9.34314575 9,11 C9,12.6568542 10.3431458,14 12,14 Z M12,8 L12,5 M12,17 L12,14 M6,11 L9,11 M15,11 L18,11 M8,7 L10,9 M14,13 L16,15 M16,7 L14,9 M10,13 L8,15"}}]})(props);
};

export const Nav = styled.nav`
  background: #101522;
  height: 80px;
  display: flex;
  justify-content: content;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const NavberContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 80px;

  ${Container}
`;
export const NavLogo = styled.div`
  color: #fff;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 36px;
  display: flex;
  align-items: center;
`;
export const NavLogoImage = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 50px;
  margin-right : 10px;
  border-radius : 70%
`;
export const NavIcon = styled(GrShieldSecurity)`
  margin-right: 10px;
  color: #fff;
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
`;

export const NavItem = styled.li`
  height: 80px;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #fff;
  }
`;

export const NavLinks = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 10px 20px;
  height: 100%;

  &:hover {
  }
`;
export const NavBtnLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;
