import React from 'react';
import { Container } from '../../PageTemplate';
import {
  HeadText,
  SecColumn,
  SecContainer,
  SecRow,
  SubTitle,
  TextWrapper,

  // SubTitleLink,
} from './section.elements';

// Second Section 
// 暗号資産盗難追跡プロセス
// スペシャリストがグローバルブロックチェーンセキュリティ専門企業と連携し、
// あなたのウォレットアドレスをリアルタイムに追跡、調査します。
// そしてどの取引所に資金が流出したのかを突き止めます。

const SeqSection = ({
  firstSection,
  buttonlabel,
  headline,
  img,
  description1,
  description2,
  description3,
  alt,
  seqSec,
  width,
}) => {
  return (
    <>
      <SecContainer seqSec padding="50px">
        <Container>
          <SecRow seqSec>
            <SecColumn>
              <TextWrapper width="550px" seqSec>
                <HeadText seqSec margintop="10px" paddingleft="85px">{headline}</HeadText>              
              </TextWrapper>
            </SecColumn>
            <SecColumn>
            <SubTitle width="36em" seqSec>{description1}</SubTitle>
            <SubTitle width="570px" seqSec>{description2}</SubTitle>
            <SubTitle width="570px" seqSec>{description3}</SubTitle>
            </SecColumn>
          </SecRow>
        </Container>
      </SecContainer>
    </>
  );
};
export default SeqSection;
