import styled from 'styled-components';
import SectionImg1 from '../../img/img_asset_1.png';
import SectionImg2 from '../../img/img_asset_3.png';
// import BackgroundIMG from '../../img/background_1.png';
export const SecContainer = styled.div`
  color: #f1f3f5;
  padding: ${({ padding }) => padding};
  background: ${({ insure }) => (insure ? '#fff' : '#f1f3f5')};
  background-image: ${({ firstSection,secondSection }) =>
    (firstSection ? `url(${SectionImg1})` : secondSection ? `url(${SectionImg2})` : 'none')};
    background-repeat : no-repeat;
`;

export const SecRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: inherit;
  align-items: stretch;
  /* flex-direction: ${(firstSection) =>
    firstSection ? 'none' : 'row-reverse'}; */
  justify-content: center;
  margin-top : ${({howto}) => (howto ? '20px' : 0)};
  margin-left : ${({seqSec}) => (seqSec ? '10px' : '')};
`;

export const SecColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;

  flex: 1;
  max-width: ${(insure, dmgSec) => (insure ? '680px' : dmgSec ? '140px' :'50%')};
  // width : ${({width}) => width};
  flex-basis: ${({dmgSec}) => dmgSec? '33%' : '50%'};
`;
export const SmallSecColumn = styled.div`
  margin-bottom: 15px;
  // padding-right: 15px;
  // padding-left: 15px;
  flex: 1;
  // max-width: 33%;
  max-width : ${({maxwidth}) => maxwidth};
  flex-basis: 50%;
`;

export const TextWrapper = styled.div`
  // max-width: 650px;
  // width: ${({seqSec}) => (seqSec ? '480px' : '450px')};
  width : ${({width}) => width};
  padding-top: 0;
  padding-bottom: ${({seqSec}) => (seqSec ? 0 : '60px')};
  text-align : ${({DmgFirstHead}) => (DmgFirstHead ? 'center' : '')};
  margin-left : ${({seqSec}) => (seqSec ? '16px' : 0)};
`;
export const SmallTextWrapper = styled.div`
  // max-width: 540px;
  width : ${({width}) => width};
  padding-left : ${({paddingLeft}) => paddingLeft};
  padding-top: 10px;
  padding-bottom: 60px;
  padding-right : 20px;
  border-top : 5px solid #dee2e6; 
  border-collapse : collapse;
  &:hover {
    border-top: 5px solid #364fc7;
  }
`;

export const TopLine = styled.div`
  color: #000;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const HeadText = styled.h1`
  /* margin-bottom: ${({seqSec,dmgSec}) => (seqSec ? '62px' : dmgSec ? '30px' : '24px')}; */
  margin-bottom : ${({marginBottom}) => marginBottom};
  margin-top : ${({margintop}) => margintop};
  width :${({width}) => width};
  padding-left :${({paddingleft}) => paddingleft};
  font-size: ${({seqSec}) => (seqSec ? '34px' : '48px')};
  padding-top :  ${({seqSec,paddingTop}) => (seqSec ? 0 : paddingTop ?? '20px')};
  line-height: 1.1;
  color: ${({seqSec}) => (seqSec ? '#000' : '#f7f8fa')};
`;

export const SubTitle = styled.p`
  // max-width: ${({DmgFirstHead}) => (DmgFirstHead ? '650px' : '440px' )};
  // width: ${({DmgFirstHead}) => (DmgFirstHead ? '650px' : '440px' )};
  width :${({width}) => width};
  margin-bottom: ${({seqSec,marginBottom}) => (seqSec || marginBottom ? 0 : '35px')};
  font-size: ${({fontSize}) => (fontSize ??  '18px')};
  line-height: 24px;
  color: ${({seqSec}) => (seqSec ? '#000' : '#a9b3c1')};
`;
export const SubTitleLink = styled.p`  /* 수정 요소 */
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #339af0;
  cursor : pointer;
  text-decoration : underline;

  &:hover {
    color: #364fc7;
  }
`;

export const ImgWrapper = styled.div`
  max-width: ${({ insure }) => (insure ? '640px' : '555px')};
  
  display: flex;
  justify-content: ${({dmgSec}) => (dmgSec ? 'center' : 'flex-start')};
`;

export const Image = styled.img`
  // padding-right: ${({ insure }) => (insure ? '150px' : 0)};
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 620px;
`;

export const BigImage = styled.img`
  border : 0; 
  width:  100%;
  vertical-align: middle;
  max-height: 800px;
`;