import Navbar from './components/Navigation/Navbar';
// import JustImageSection from './components/Sections/JustImageSection';
import Section from './components/Sections/Section';
import { infoData, SeqData, HowtoData, DmgData, ConsultData } from './datas/Data';
import PageTemplate from './PageTemplate';
import SeqSection from './components/Sections/SeqSection';
import ConsultSection from './components/Sections/ConsultSection';
import HowtoSection from './components/Sections/HowToSection';
import DmgSection from './components/Sections/DmgSection';
import Footer from './components/Footer/Footer';
import RecpprocessSection from './components/Sections/RecpprocessSection';

function Home() {
  return (
    <>
      <PageTemplate />
      <Navbar />
      <Section {...infoData} />
      {/* <JustImageSection /> */}
      <SeqSection {...SeqData}/>
      <HowtoSection {...HowtoData}/>
      <DmgSection {...DmgData}/>
      <RecpprocessSection/>
      <ConsultSection {...ConsultData}/>
      <Footer/>
    </>
  );
}

export default Home;
