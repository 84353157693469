import React from 'react';
import { Button, RecepBtnOnClick } from '../../PageTemplate';
import CoinTanTeiLogo from '../../img/CoinTanTeiLogo.png';
import {
  Nav,
  NavberContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavLogoImage,
} from './NavBar.elements';

function Navbar({ color }) {
  return (
    <Nav>
      <NavberContainer>
        <NavLogo>
          <NavLogoImage src={CoinTanTeiLogo} />
          コイン探偵
        </NavLogo>
        <NavMenu>
          {/* <NavItem>
            <NavLinks>Button1</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks>Button2</NavLinks>
          </NavItem> */}
          <NavItem>
            <NavLinks>
              <Button onClick={RecepBtnOnClick}>受付はこちらへ</Button>
            </NavLinks>
          </NavItem>
        </NavMenu>
      </NavberContainer>
    </Nav>
  );
}

export default Navbar;
