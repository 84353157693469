import React from 'react';
import {  Container } from '../../PageTemplate';
import TradingCtImage from '../../img/img_asset_9.png'
import DigitalAssetImage from '../../img/img_asset_8.png'
import IllTradeImage from '../../img/img_asset_10.png'
import {
  HeadText,
  Image,
  ImgWrapper,
  SecColumn,
  SecContainer,
  SecRow,
  SubTitle,
  TextWrapper,
} from './section.elements';

// 4th Section 
// デジタル資産被害事例
// 「COINTANTEI」は取引所ハッキング、投資詐欺、違法取引などの様々なデジタル資産金融犯罪に関して、被害を受けた資金を取り戻すための法律サービスを法務法人と共にご提供いたします

const DmgSection = ({
  firstSection,
  buttonlabel,
  headline1,
  headline2,
  headline3,
  headline4,
  img,
  description1,
  description2,
  description3,
  description4, 
  alt,
  width,
}) => {
  return (
    <>
      <SecContainer   padding="50px">
        <Container>
          <SecRow>
              <TextWrapper width="680px" DmgFirstHead>
                  <HeadText width="680px" seqSec marginBottom="40px">{headline1}</HeadText>
                  <SubTitle width="680px" DmgFirstHead seqSec> {description1}</SubTitle>
              </TextWrapper>
          </SecRow>
          <SecRow >
            <SecColumn width="410px" dmgSec>
              <TextWrapper width="380px" DmgFirstHead>
              <ImgWrapper dmgSec>
                <Image src={TradingCtImage}></Image>
              </ImgWrapper>
                <HeadText seqSec marginBottom="30px">{headline2}</HeadText>
                <SubTitle  width="380px" seqSec>{description2}</SubTitle>
              </TextWrapper>
            </SecColumn>
            <SecColumn width="410px" dmgSec>
              <TextWrapper width="380px"DmgFirstHead>
              <ImgWrapper dmgSec>
                <Image src={DigitalAssetImage}></Image>
              </ImgWrapper>
                <HeadText seqSec marginBottom="30px">{headline3}</HeadText>
                <SubTitle  width="380px" seqSec >{description3}</SubTitle>
              </TextWrapper>
            </SecColumn>
            <SecColumn width="410px" dmgSec>
              <TextWrapper width="380px"DmgFirstHead>
              <ImgWrapper dmgSec>
                <Image src={IllTradeImage}></Image>
              </ImgWrapper>
                <HeadText seqSec marginBottom="30px">{headline4}</HeadText>
                <SubTitle  width="380px" seqSec >{description4}</SubTitle>
              </TextWrapper>
            </SecColumn>
          </SecRow>
        </Container>
      </SecContainer>
    </>
  );
};
export default DmgSection;
