import styled, { createGlobalStyle } from 'styled-components';

const PageTemplate = createGlobalStyle`
  *{
  box-sizing: border-box;
  margin:0;
  padding:0;
  }
  `;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  font-size: 20px;
`;

export const Button = styled.button`
  border-radius: 4px;
  background: #0467fb;
  white-space: nowrap;
  padding: 12px 64px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 20px;

  &:hover {
  }
`;
export function RecepBtnOnClickOnMail() {
  var link = "mailto:cointantei@bridged.jp";
  document.location.href = link;
}
export function RecepBtnOnClick() {
  var link = "https://docs.google.com/forms/d/e/1FAIpQLSeyzJ360m69m83ZQ-kUlOb27Y3TyJ2eTuBqpvHKdYvKuWmSMA/viewform?usp=sf_link";
  document.location.href = link;
}

export default PageTemplate;
