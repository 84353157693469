import React from 'react';
import { FooterContainer, FooterLogoImage, FooterInnerContainer,FooterLogo,FooterTextWrapper, FooterEmailImg, FooterEImg, FooterTwImg,FooterTwitterImg,FooterFlexContainer } from './Footer.elements';
import CoinTanTeiLogo from '../../img/cointantei.jpg';

// Footer

function Footer({logo,footerright}) {
    const thisYear = () => {
      const year = new Date().getFullYear();
      return year;
    };
  return (
    <>
    <FooterContainer>
        <FooterInnerContainer logo>
            <FooterLogo>
               <FooterLogoImage src={CoinTanTeiLogo}/>
             COINTANTEI
            </FooterLogo>
         </FooterInnerContainer>        
    </FooterContainer>
    <FooterContainer>
        <FooterInnerContainer>
         <FooterTextWrapper>
         〒104-0041
         東京都中央区新富1-8-9
         THE GATE GINZA EAST 2F
         株式会社BRIDGED
         Tel: 03-5953-8430
         </FooterTextWrapper>
         </FooterInnerContainer>
         <FooterFlexContainer>
         <FooterInnerContainer footerright>
         <FooterTextWrapper footerright>
            <FooterEmailImg href="mailto:cointantei@bridged.jp" target="blank" rel="noreferrer">
                <FooterEImg/>
            </FooterEmailImg>
            <FooterTwitterImg href="https://twitter.com/cointantei" target="blank" rel="noreferrer">
                <FooterTwImg/>
            </FooterTwitterImg>
         </FooterTextWrapper>
         <FooterTextWrapper footerright>
         Copyright © {thisYear()} BRIDGE.D All Rights Reserved.   
         </FooterTextWrapper>
         </FooterInnerContainer>
         </FooterFlexContainer>
        
    </FooterContainer>
    </>
  );
}

export default Footer;
