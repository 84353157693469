import React from 'react';
import { Button, Container, RecepBtnOnClick } from '../../PageTemplate';
import {
  HeadText,
  Image,
  ImgWrapper,
  SecColumn,
  SecContainer,
  SecRow,
  SubTitle,
  TextWrapper,
  // TopLine,
} from './section.elements';

// First Section 
// 暗号資産盗難追跡サービス
// もし暗号資産（仮想通貨）の盗難、詐欺被害に遭ったら・・・
// コイン探偵のスペシャリストがご依頼人の暗号資産を取り戻すため
// 追跡から法的な手続きまでをサポートいたします。

const Section = ({
  firstSection,
  buttonlabel,
  headline,
  img,
  description1,
  description2,
  description3,
  alt,
  width,
  head2,
}) => {
  return (
    <>
      <SecContainer firstSection padding="170px">
        <Container>
          <SecRow firstSection>
            <SecColumn>
              <TextWrapper width="450px">
                <HeadText width="15em">{headline}</HeadText>
                {/* <HeadText paddingTop="2px" marginBottom="62px">{head2}</HeadText> */}
                <SubTitle marginBottom width="30em">{description1}</SubTitle>
                <SubTitle marginBottom width="30em">{description2}</SubTitle>
                <SubTitle width="30em">{description3}</SubTitle>
                <Button onClick={RecepBtnOnClick}>{buttonlabel}</Button>
              </TextWrapper>
            </SecColumn>
            <SecColumn>
              <ImgWrapper>
                <Image></Image>
              </ImgWrapper>
            </SecColumn>
          </SecRow>
        </Container>
      </SecContainer>
    </>
  );
};
export default Section;
