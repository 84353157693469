import React from 'react';
import { Button, Container, RecepBtnOnClickOnMail } from '../../PageTemplate';
import {
  HeadText,
  Image,
  ImgWrapper,
  SecColumn,
  SecContainer,
  SecRow,
  SubTitle,
  TextWrapper,
  TopLine,
} from './section.elements';

// 6th Section 
// 暗号通貨犯罪専門
// コンサルティング
// デジタル資産の追跡から取り戻すための法律的な手続きまで複数の専門家と一緒に進めてみてください。

const Section = ({
  firstSection,
  secondSection,
  buttonlabel,
  headline,
  headline2,
  img,
  description,
  alt,
  width,
}) => {
  return (
    <>
      <SecContainer secondSection padding="55px">
        <Container>
          <SecRow firstSection>
            <SecColumn>
              <TextWrapper width="387px">
                <TopLine></TopLine>
                <HeadText marginBottom="0px">{headline}</HeadText>
                <HeadText paddingTop="3px"marginBottom="62px">{headline2}</HeadText>
                <SubTitle>{description}</SubTitle>
                <Button onClick={RecepBtnOnClickOnMail}>{buttonlabel}</Button>
              </TextWrapper>
            </SecColumn>
            <SecColumn>
              <ImgWrapper>
                <Image></Image>
              </ImgWrapper>
            </SecColumn>
          </SecRow>
        </Container>
      </SecContainer>
    </>
  );
};
export default Section;
