import styled from 'styled-components';
import { Container } from '../../PageTemplate';
import { AiOutlineMail } from 'react-icons/ai';
import { FiTwitter } from 'react-icons/fi';

export const FooterContainer = styled.div`
  height: 151px;
  padding: 40px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: content;
  align-items: flex-end;
  position: sticky;
  top: 0;
  z-index: 999;
`;
export const FooterFlexContainer = styled.div`
  display: flex;
`;
export const FooterLogo = styled.div`
  color: #fff;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 36px;
  display: flex;
  align-items: center;
`;

export const FooterLogoImage = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 80px;
  margin-right: 10px;
  border-radius: 70%;
`;

export const FooterInnerContainer = styled(Container)`
  display: ${({ footerright }) => (footerright ? 'block' : 'flex')};
  justify-content: ${({ logo }) => (logo ? 'center' : 'end')};
  height: 60px;

  ${Container}
`;

export const FooterTextWrapper = styled.div`
  // max-width: ${({ footerright }) => (footerright ? '458px' : '400px')};
  width: ${({ footerright }) => (footerright ? '458px' : '400px')};
  padding-top: ${({ footerright }) => (footerright ? '5px' : '40px')};
  padding-bottom: 0;
  padding-right: 0;
  padding-left: ${({ footerright }) => (footerright ? '120px' : 0)};;
  font-size: 15px;
`;

export const FooterEmailImg = styled.a``;
export const FooterTwitterImg = styled.a``;
export const FooterEImg = styled(AiOutlineMail)`
  vertical-align: middle;
  font-size: 25pt;
  color: #fff;
  &:hover {
    color: #e9ecef;
  }
`;
export const FooterTwImg = styled(FiTwitter)`
  vertical-align: middle;
  font-size: 25pt;
  margin-left: 10px;
  color: #fff;
  &:hover {
    color: #e9ecef;
  }
`;
