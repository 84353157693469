export const infoData = {
  buttonlabel: '受付はこちらへ',
  headline: `暗号資産盗難追跡サービス`,
  // head2: '追跡サービス',
  description1:
    'もし暗号資産（仮想通貨）の盗難、詐欺被害に遭ったら・・・',
  description2:
    'コイン探偵のスペシャリストがご依頼人の暗号資産を取り戻すため',
  description3:
    '追跡から法的な手続きまでをサポートいたします。',
  img: require('../img/img_asset_1.png'),
  alt: 'デジタル資産詐欺被害セクション',
};

export const SeqData = {
  headline: '暗号資産盗難追跡プロセス',
  description1:
    'スペシャリストがグローバルブロックチェーンセキュリティ専門企業と連携し、',
  description2:
    'あなたのウォレットアドレスをリアルタイムに追跡、調査します。',
  description3:
    'そしてどの取引所に資金が流出したのかを突き止めます。',
};

export const HowtoData = {
  headline1: '追跡調査のご依頼',
  description1_1:
    '「コイン探偵」へ被害額、発生日、',
  description1_2:
    'ウォレットアドレスなどの追跡調査に',
  description1_3:
    '必要な情報をご提供いただきます。',  
  headline2: '資産の追跡',
  description2_1:
    '「コイン探偵」は依頼内容に基づいて取引を分析し、',
  description2_2:
    '資産が流出したルートや',
  description2_3:
    '被疑者の情報などを調査いたします。',
  headline3: '証拠資料のご提供',
  description3_1:
    '調査結果を纏めた資料をお渡しします。',
  description3_2:
    '「コイン探偵」にて作成した追跡レポートは',
  description3_3:
    '法的な効力のある証拠資料として',
  description3_4:
    '司法機関に提出可能な資料です。',
};

export const DmgData = {
  headline1: 'デジタル資産被害事例',
  description1:
    '「COINTANTEI」は取引所ハッキング、投資詐欺、違法取引などの様々なデジタル資産金融犯罪に関して、被害を受けた資金を取り戻すための法律サービスを法務法人と共にご提供いたします',
  headline2: '取引所ハッキング被害',
  description2:
    '取引所に保存していたデジタル資産をハッキングされたり、アカウントを乗っ取られたりして金銭的な被害を受けましたか？',
  headline3: 'デジタル資産投資詐欺',
  description3:
    '仮想資産におけるマルチ商法、もしくは投資詐欺の被害を受けましたか？',
  headline4: '違法取引との絡み',
  description4:
    'デジタル資産の振り込み、送金詐欺など、あなたも知らず知らずのうちに違法取引に絡み、被害を受けましたか？',
};

export const ConsultData = {
  buttonlabel: '被害の受付',
  headline: `暗号通貨犯罪専門`,
  headline2: `コンサルティング`,
  description:
    'デジタル資産の追跡から取り戻すための法律的な手続きまで複数の専門家と一緒に進めてみてください。',
  img: require('../img/img_asset_3.png'),
  alt: 'デジタル資産詐欺被害セクション',
};
