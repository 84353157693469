import React from 'react';
import {  Container } from '../../PageTemplate';
import RecepImage from '../../img/recept_img_red.jpeg'
import {
//   HeadText,
//   // Image,
//   // ImgWrapper,
//   SmallSecColumn,
  SecContainer,
  SecRow,
//   SubTitle,
//   SmallTextWrapper,
//   TopLine,
  BigImage,
} from './section.elements';

// 5th Section 
// Process Diagram

const RecpprocessSection = ({
  firstSection,
  buttonlabel,
  headline1,
  headline2,
  headline3,
  img,
  description1,
  description2,
  description3,
  howto,
  alt,
  seqSec
}) => {
  return (
    <>
      <SecContainer padding="20px" insure>
        <Container>
          <SecRow >          
                <BigImage src={RecepImage}></BigImage>      
          </SecRow>
        </Container>
      </SecContainer>
    </>
  );
};
export default RecpprocessSection;
