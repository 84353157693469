import React from 'react';
import {  Container } from '../../PageTemplate';
import TrackCoin from '../../img/tracking-process-ed.jpeg'
import {
  HeadText,
  // Image,
  // ImgWrapper,
  SmallSecColumn,
  SecContainer,
  SecRow,
  SubTitle,
  SmallTextWrapper,
  TopLine,
  BigImage,
} from './section.elements';

// Third Section 
// Diagram and three dialog

const HowToSection = ({
  firstSection,
  buttonlabel,
  headline1,
  headline2,
  headline3,
  img,
  description1_1,
  description1_2,
  description1_3,
  description2_1,
  description2_2,
  description2_3,
  description3_1,
  description3_2,
  description3_3,
  description3_4,
  howto,
  alt,
  seqSec,
  width,
  paddingLeft,
}) => {
  return (
    <>
      <SecContainer padding="0" insure>
        <Container>
          <SecRow >          
                <BigImage src={TrackCoin}></BigImage>      
          </SecRow>
          <SecRow howto> 
          <SmallSecColumn maxwidth="32%">
              <SmallTextWrapper  >
                <TopLine></TopLine>
                <HeadText seqSec marginBottom="24px">{headline1}</HeadText>
                <SubTitle  width="360px" seqSec>{description1_1}</SubTitle>
                <SubTitle  width="360px" seqSec>{description1_2}</SubTitle>
                <SubTitle  width="360px" seqSec>{description1_3}</SubTitle>
              </SmallTextWrapper>
          </SmallSecColumn>  
          <SmallSecColumn maxwidth="35%">
              <SmallTextWrapper  >
                <TopLine></TopLine>
                <HeadText seqSec marginBottom="24px">{headline2}</HeadText>
                <SubTitle  fontSize="17px" width="25em" seqSec>{description2_1}</SubTitle>
                <SubTitle  width="360px"seqSec>{description2_2}</SubTitle>
                <SubTitle  width="360px"seqSec>{description2_3}</SubTitle>
              </SmallTextWrapper >
          </SmallSecColumn>  
          <SmallSecColumn maxwidth="32%">
              <SmallTextWrapper  paddingLeft="15px">
                <TopLine></TopLine>
                <HeadText seqSec marginBottom="24px">{headline3}</HeadText>
                <SubTitle  width="360px"seqSec>{description3_1}</SubTitle>
                <SubTitle  width="360px"seqSec>{description3_2}</SubTitle>
                <SubTitle  width="360px"seqSec>{description3_3}</SubTitle>
                <SubTitle  width="360px"seqSec>{description3_4}</SubTitle>
              </SmallTextWrapper>
          </SmallSecColumn>  
          </SecRow>
        </Container>
      </SecContainer>
    </>
  );
};
export default HowToSection;
